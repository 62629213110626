import axios from 'axios'
import { PayoutTicketActionResponse } from './_models'
import { API_URL } from '../../../../constants'
import Swal from 'sweetalert2'

export const IN_PROGRESS_TOKEN = `${API_URL}/api/payout/in_progress/__token__`
export const FINISH_TOKEN = `${API_URL}/api/payout/complete/__token__`
export const CANCEL_TOKEN = `${API_URL}/api/payout/decline/__token__`
export const CHANGE_ADAPTER = `${API_URL}/api/payout/change-payment-adapter/__token__`

export function inProgressTicketAPI(token: string) {
  return axios.post<PayoutTicketActionResponse>(IN_PROGRESS_TOKEN.replace('__token__', token))
}

export function finishTicketAPI(token: string, commission?: string) {
  return axios.post<PayoutTicketActionResponse>(
    FINISH_TOKEN.replace('__token__', token),
    commission && {
      commission,
    }
  )
}

export function cancelTicketAPI(token: string) {
  return axios.post<PayoutTicketActionResponse>(CANCEL_TOKEN.replace('__token__', token))
}

export async function changPaymentTicketAPI(token: string) {
  const response = await axios.post(CHANGE_ADAPTER.replace('__token__', token))
  if (response.status !== 200) {
    Swal.fire('Ошибка', 'Невозможно отказаться от тикета', 'error')
  } else {
    return response
  }
}
