import axios from 'axios'
import { IReportTicket } from '../ReportsPage.types'
import { DETAILED_PAYMENT_TICKETS_URL, DETAILED_PAYMENT_TRANSACTIONS_URL, DETAILED_PAYOUT_TICKETS_URL, DETAILED_PAYOUT_TRANSACTIONS_URL } from './consts'

export interface IReportTicketsParamsRequest {
  dateStart: string
  dateEnd?: string
  merchantTokenList: string[]
  cashBoxIdList: number[]
  page?: number
  perPage?: number
}

export interface IReportTicketsListResponse {
  data: IReportTicket[]
  success: boolean
  totalRows: number
  errors: any
}

export const getReportTicketsList = (
  params: IReportTicketsParamsRequest,
  reportType = 'payment'
) => {
  const url = reportType === 'payment' ? DETAILED_PAYMENT_TICKETS_URL : DETAILED_PAYOUT_TICKETS_URL

  return axios.post<IReportTicketsListResponse>(url, params)
}

export const getPaymantTicketListReport = (
  params: IReportTicketsParamsRequest,
  reportType = 'payment'
) => {
  const url = reportType === 'payment' ? DETAILED_PAYMENT_TRANSACTIONS_URL : DETAILED_PAYOUT_TRANSACTIONS_URL

  return axios.post<IReportTicketsListResponse>(url, params)
}
