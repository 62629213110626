import { env } from '../../../../env'
import axios from 'axios'
import { ClientInfoResponse, ClientListFilterType, ClientListResponse, ClientRequisiteId } from './_rest_models'
import { ROLE, UserModel } from '../../auth'
import { getUserRolesByHierarchy } from '../../auth/PrivateRoute'
import { ClientEditRequest } from './_models'
import { IClientsExportRequest } from '../../../../components/ClientsSearch/ClientsSearch.types'

const API_URL = env.REACT_APP_API_URL

export const GET_EMPLOYEE_LIST_CLIENTS = `${API_URL}/api/internal/external_user/list`
export const GET_LIST_CLIENTS = `${API_URL}/api/external_user/list`
export const GET_EMPLOYEE_CLIENT_INFO = `${API_URL}/api/internal/external_user/__token__`
export const GET_CLIENT_INFO = `${API_URL}/api/external_user/__token__`
export const EDIT_CLIENT = `${API_URL}/api/internal/external_user/edit/__token__`
export const CLIENTS_EXPORT = `${API_URL}/api/internal/reports/external-users`

export function getListClientsForSearch(
  filterData: Partial<ClientListFilterType>,
  currentUser?: UserModel
) {
  if (!filterData.perPage) {
    filterData.perPage = 99
  }

  if (!filterData.page) {
    filterData.page = 1
  }

  let params: any = { ...filterData }
  params.requisiteId = filterData.requisiteId?.map((el: ClientRequisiteId) => el.id)

  const allRolesCurrentUser = getUserRolesByHierarchy(currentUser)
  const url = allRolesCurrentUser.includes(ROLE.ROLE_EMPLOYEE)
    ? GET_EMPLOYEE_LIST_CLIENTS
    : GET_LIST_CLIENTS

  return axios.get<Partial<ClientListResponse>>(url, {
    params: params,
  })
}

export function getClientByToken(token: string, currentUser?: UserModel) {
  const allRolesCurrentUser = getUserRolesByHierarchy(currentUser)
  const url = allRolesCurrentUser.includes(ROLE.ROLE_EMPLOYEE)
    ? GET_EMPLOYEE_CLIENT_INFO
    : GET_CLIENT_INFO

  return axios.get<Partial<ClientInfoResponse>>(url.replace('__token__', token))
}

export function editClientByToken(token: string, updateData: ClientEditRequest) {
  return axios.post<Partial<ClientInfoResponse>>(
    EDIT_CLIENT.replace('__token__', token),
    updateData
  )
}

export function postClientsExport(query: IClientsExportRequest) {
  return axios.post<Partial<ClientInfoResponse>>(CLIENTS_EXPORT, query, {
    responseType: 'blob',
  })
}
