import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { getListTransactions } from '../core/_requests'
import { PaymentTicketWithTransactions, TicketListObject } from '../core/_models'
import FinishedTicketItem from './FinishedTicketItem'
import FinishedTicketFilter from './FinishedTicketsFilter'
import { Table } from '../../../../components/Table'
import { TableHead } from '../../../../components/Table/TableHead'
import { TableBody } from '../../../../components/Table/TableBody'
import { ROLE, useAuth } from '../../auth'
import { IsGranted } from '../../auth/PrivateRoute'
import { ETicketStatus } from '../../../../types/enums'
import { getDefaultDateRange } from '../../../../utils/getDefaultDateRange'
import { convertToUTCByMSK } from '../../../../utils'

import '../../../assets/css/pagination.css'
import StatusModal from './StatusModal'
import fileDownload from 'js-file-download'
import { format } from 'date-fns'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/payment-tickets/active',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Завершённые',
    path: '/payment-tickets/finished',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
]

const perPage = 20

const FinishedTickets = () => {
  const { currentUser } = useAuth()
  const [tickets, setTickets] = useState<PaymentTicketWithTransactions[]>([])
  const { startDate, endDate } = getDefaultDateRange()
  const [filters, setFilters] = useState<any>({
    ticketStatus: [ETicketStatus.DECLINED, ETicketStatus.COMPLETED, ETicketStatus.EXPIRED],
    dateStart: startDate,
    dateEnd: endDate,
    createdDateStart: convertToUTCByMSK(startDate),
    createdDateEnd: convertToUTCByMSK(endDate),
    perPage: perPage,
  })
  const [countPages, setCountPages] = useState(0)
  const [fullCount, setFullCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [statusModal, setStatusModal] = useState<boolean>(false)
  const [targetTicket, setTargetTicket] = useState<PaymentTicketWithTransactions | null>(null)

  const updateTicketList = async (
    toFirstPage: boolean = true,
    reset: boolean = false,
    exportCsv: boolean = false,
    page: number = 1
  ) => {
    let query: any = {}

    if (reset) {
      query = {
        ticketStatus: ['DECLINED', 'COMPLETED', 'EXPIRED'],
        dateStart: convertToUTCByMSK(startDate),
        dateEnd: convertToUTCByMSK(endDate),
        perPage: 20,
        page: 1,
        byCompletionDate: false,
      }
      setFilters({
        ...query,
        dateStart: startDate,
        dateEnd: endDate,
      })
    } else {
      const {
        dateStart,
        dateEnd,
        createdDateStart,
        createdDateEnd,
        completionDateStart,
        completionDateEnd,
        cashBoxId,
        merchantTokenList,
        paymentMethod,
        ticketProcessType,
        search,
        ...rest
      } = filters
      query = {
        ...rest,
        dateStart: convertToUTCByMSK(dateStart),
        dateEnd: convertToUTCByMSK(dateEnd),
        completionDateStart: completionDateStart && convertToUTCByMSK(completionDateStart),
        completionDateEnd: completionDateEnd && convertToUTCByMSK(completionDateStart),
        cashBoxIdList: cashBoxId?.[0],
        merchantTokenList: merchantTokenList,
        paymentMethodList: paymentMethod?.[0],
        ticketProcessType: ticketProcessType?.[0],
        search: search,
        page: page,
      }
    }

    if (toFirstPage) {
      query.page = 1
    }

    try {
      let response

      if (exportCsv) {
        response = await getListTransactions({ ...query, csvExport: true }, exportCsv)

        if (response.data) {
          fileDownload(
            response.data,
            `payment-history-${format(new Date(query.dateStart), 'dd-MM-yyyy_HH-mm')}__${format(
              new Date(query.dateEnd),
              'dd-MM-yyyy_HH-mm'
            )}.csv`
          )
        }
      } else {
        setLoading(true)
        response = await getListTransactions(query)

        if (!response.data) {
          return false
        }

        if (response.data.success && response.data.list) {
          setTickets(response.data.list)
          setCountPages(Math.ceil(response.data.totalRows / perPage))
          setFullCount(response.data.totalRows)
          setCurrentPage(query.page ? query.page : 1)
        }

        setLoading(false)
      }
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  useEffect(() => {
    if (
      IsGranted(
        [
          ROLE.ROLE_ADMIN,
          ROLE.ROLE_MANAGER,
          ROLE.ROLE_CASHIER,
          ROLE.ROLE_SENIOR_CASHIER,
          ROLE.ROLE_SENIOR_OPERATOR,
          ROLE.ROLE_OPERATOR,
        ],
        currentUser
      )
    ) {
      updateTicketList()
    }
  }, [])

  const ticketsRender = tickets?.map((el: PaymentTicketWithTransactions) => {
    return (
      <FinishedTicketItem
        ticket={el}
        key={el?.ticketToken}
        setShowAlert={setShowAlert}
        setStatusModal={setStatusModal}
        setTargetTicket={setTargetTicket}
        targetTicket={targetTicket}
        setTickets={setTickets}
      />
    )
  })

  return (
    <div style={{ position: 'relative' }}>
      <StatusModal
        isOpen={statusModal}
        setIsOpen={setStatusModal}
        targetTicket={targetTicket}
        setTargetTicket={setTargetTicket}
        setTickets={setTickets}
      />
      <div>
        <div className='card sticky-top-tickets mb-7'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Завершённые</PageTitle>
          <div className='card-body border-0 pt-5 pb-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Завершённые тикеты</span>
            </h3>
          </div>
        </div>

        <div className='card mb-7'>
          <div className='card-body border-0 pt-5'>
            <FinishedTicketFilter
              initialFilter={filters}
              updateFilters={setFilters}
              callback={(param1?: boolean, param2?: boolean, exportCsv?: boolean) =>
                updateTicketList(param1, param2, exportCsv)
              }
            />
          </div>
        </div>

        {IsGranted(
          [
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_CASHIER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
            ROLE.ROLE_OPERATOR,
          ],
          currentUser
        ) ? (
          <>
            <div className='card'>
              <div className='card-body py-3'>
                {showAlert && (
                  <div
                    style={{
                      top: '10vh',
                      left: '50%',
                      zIndex: 10000,
                      transform: 'translate(-50%, -50%)',
                    }}
                    className='alert alert-success position-fixed min-w-200px w-300px'
                  >
                    Скопировано
                  </div>
                )}
                <Table>
                  <TableHead>
                    <tr>
                      <th className='min-w-150px'>ID</th>
                      <th className='min-w-70px'>Платежный метод</th>
                      <th className='min-w-140px'>Дата создания</th>
                      <th className='min-w-140px'>Дата закрытия</th>
                      <th className='w-60px'>Клиент</th>
                      <th className='min-w-100px'>Сумма</th>
                      {IsGranted(
                        [
                          ROLE.ROLE_ADMIN,
                          ROLE.ROLE_MANAGER,
                          ROLE.ROLE_CASHIER,
                          ROLE.ROLE_SENIOR_CASHIER,
                        ],
                        currentUser
                      ) ? (
                        <th className='min-w-150px text-center'>Статус</th>
                      ) : (
                        <></>
                      )}
                    </tr>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <tr>
                        <td colSpan={10}>Загрузка ...</td>
                      </tr>
                    ) : (
                      ticketsRender
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>

            <ReactPaginate
              activeClassName={'active-pagination '}
              breakClassName={'item-pagination break-me-pagination '}
              containerClassName={'pagination-pagination'}
              disabledClassName={'disabled-page-pagination'}
              nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
              pageClassName={'item-pagination pagination-page-pagination '}
              previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
              breakLabel='...'
              nextLabel=''
              onPageChange={(pageNumber) => {
                setFilters({
                  ...filters,
                  page: pageNumber.selected + 1,
                })

                updateTicketList(false, false, false, pageNumber.selected + 1)
              }}
              pageRangeDisplayed={5}
              pageCount={countPages || 1}
              previousLabel=''
              renderOnZeroPageCount={null}
              forcePage={currentPage - 1}
            />
          </>
        ) : (
          'Роль юзера не имеет соответствующих прав для просмотра детального отчета.'
        )}
      </div>
    </div>
  )
}

export default FinishedTickets
