import { formatSum } from '../../../../utils'
import { PaymentTicketWithTransactions } from '../core/_models'
import { IsGranted } from '../../auth/PrivateRoute'
import { ROLE, useAuth } from '../../auth'
import { convertUTCtoMSK } from '../../../../utils'
import { internalCancelTicketAPI } from '../core/_requests'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Select, { SingleValue } from 'react-select'
import { SelectStyles } from '../../../../components/Select/Select.theme'
import { useThemeMode } from '../../../../_metronic/partials'
import { Accordion } from 'radix-ui'
import { TableHead } from '../../../../components/Table/TableHead'
import { Table } from 'react-bootstrap'
import styles from './FinishedTicketItem.module.css'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

const statusOptions = [
  {
    value: 'COMPLETED',
    label: 'Завершен',
  },
  {
    value: 'EXPIRED',
    label: 'Просрочен',
  },
]

const filterStatus = (status: string) => {
  if (status === 'EXPIRED') {
    return [
      {
        value: 'COMPLETED',
        label: 'Завершен',
      },
    ]
  } else if (status === 'COMPLETED') {
    return [
      {
        value: 'EXPIRED',
        label: 'Просрочен',
      },
    ]
  } else {
    return []
  }
}

const getStatusValue = (status: string) => {
  return statusOptions?.filter((el) => el.value === status)
}

const FinishedTicketItem = (props: {
  ticket: PaymentTicketWithTransactions
  setShowAlert: Dispatch<SetStateAction<boolean>>
  targetTicket?: PaymentTicketWithTransactions | null
  setStatusModal: Dispatch<SetStateAction<boolean>>
  setTargetTicket: Dispatch<SetStateAction<PaymentTicketWithTransactions | null>>
  setTickets: Dispatch<SetStateAction<PaymentTicketWithTransactions[]>>
}) => {
  const intl = useIntl()
  const ticket = props.ticket
  const { mode: theme } = useThemeMode()
  const [options, setOptions] = useState(statusOptions)
  const [selectValue, setSelectValue] = useState(getStatusValue(ticket?.ticketStatus))
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useAuth()

  useEffect(() => {
    setSelectValue(getStatusValue(ticket.ticketStatus))
    setOptions(filterStatus(ticket?.ticketStatus))
  }, [ticket])

  const changeStatusHandler = async (
    status: SingleValue<{
      value: string
      label: string
    }>
  ) => {
    props.setTargetTicket(ticket)
    if (status?.value === 'COMPLETED') {
      props.setTickets((prev) => {
        return prev.map((el) => {
          if (el?.ticketToken === ticket?.ticketToken) {
            return { ...el, ticketStatus: 'COMPLETED' }
          }
          return el
        })
      })
      props.setStatusModal(true)
      setSelectValue([statusOptions[0]])
    } else if (status?.value === 'EXPIRED') {
      const response = await internalCancelTicketAPI(ticket?.ticketToken)
      setSelectValue([statusOptions[1]])
      props.setTickets((prev: any) =>
        prev.map((el: PaymentTicketWithTransactions) => {
          if (el?.ticketToken === response?.data?.ticket?.token) {
            return { ...el, amount: response?.data?.ticket.amount, ticketStatus: 'EXPIRED' }
          }
          return el
        })
      )
    }
  }

  return (
    <>
      <Accordion.Root
        type='single'
        collapsible
        asChild
        className={styles.AccordionRoot}
        onValueChange={(value) => {
          if (ticket?.transactions && ticket?.transactions?.length > 0) {
            setIsOpen(() => Boolean(value))
          }
        }}
      >
        <Accordion.Item value='item-1' asChild className={styles.AccordionItem}>
          <tr
            className={clsx(
              ticket?.transactions && ticket?.transactions?.length > 0 && styles.pointer
            )}
            onClick={(e) => {
              if (e.target instanceof Element && !e.target.closest('.statusSelect')) {
                setIsOpen((prev) => !prev)
              }
            }}
          >
            <td>
              <p className='mb-4'>{ticket?.ticketToken}</p>
              <div className='d-flex-column'>
                <p className='fw-bold fs-9'>Мерчант ID:</p>
                <span className='fs-9'>{ticket?.merchantTransactionId}</span>
              </div>
            </td>
            <td>{ticket?.paymentMethod}</td>
            <td>{ticket.createdAt ? convertUTCtoMSK(ticket?.createdAt) : '-'}</td>
            <td>{ticket.completionDate ? convertUTCtoMSK(ticket?.completionDate) : '-'}</td>
            <td>
              <span className='badge'>{ticket?.userId || '-'}</span>
              <span className='separator' />
              <span className='badge'>{ticket?.externalUserId || '-'}</span>
            </td>
            <td>{ticket.amount ? formatSum(ticket.amount) : '-'}</td>
            {IsGranted(
              [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
              currentUser
            ) ? (
              <td className='w-150px statusSelect'>
                {ticket.ticketStatus === 'DECLINED' ? (
                  <p className='text-center'>Отменен</p>
                ) : (
                  <Select
                    styles={SelectStyles(theme)}
                    onChange={changeStatusHandler}
                    options={options}
                    hideSelectedOptions={true}
                    value={selectValue}
                    defaultValue={getStatusValue(ticket?.ticketStatus)}
                  />
                )}
              </td>
            ) : (
              <></>
            )}
          </tr>
        </Accordion.Item>
      </Accordion.Root>
      {isOpen ? (
        <>
          {ticket?.transactions && ticket?.transactions?.length > 0 && (
            <tr>
              <td colSpan={16}>
                <Table bordered striped={false}>
                  <TableHead>
                    <th className='min-w-150px ps-3'>Id транзакции</th>
                    <th className='min-w-140px'>Дата создания</th>
                    <th className='min-w-140px'>Дата закрытия</th>
                    <th className='min-w-100px'>Статус</th>
                    <th className='min-w-120px'>Адптер</th>
                    <th className='min-w-150px'>Название реквизита</th>
                    <th>Причина отмены</th>
                  </TableHead>
                  {props?.ticket?.transactions?.map((transaction) => {
                    return (
                      <tr key={transaction.paymentId}>
                        <td className='ps-3'>{transaction.paymentId || '-'}</td>
                        <td>{convertUTCtoMSK(transaction?.createdAt)}</td>
                        <td>
                          {(transaction?.completedAt &&
                            convertUTCtoMSK(transaction?.completedAt)) ||
                            '-'}
                        </td>
                        <td>
                          {intl.formatMessage({
                            id: `transaction.status.${transaction.status}` || '-',
                          })}
                        </td>
                        <td>{transaction.paymentAdapter || '-'}</td>
                        <td>{transaction?.requisiteName || '-'}</td>
                        <td>{transaction?.error || '-'}</td>
                      </tr>
                    )
                  })}
                </Table>
              </td>
            </tr>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default FinishedTicketItem
