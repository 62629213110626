import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { EPayoutTicketStatus } from '../../../../types/enums'
import { convertUTCtoMSK, formatSum } from '../../../../utils'
import CashBoxFilter from '../../../common/filter/CashBoxFilter'
import { SelectData } from '../../../common/filter/models'
import { ROLE, useAuth } from '../../auth'
import { IsGranted } from '../../auth/PrivateRoute'
import { changeCashbox } from '../api'
import { PayoutTicketActionResponse } from '../core/_models'
import {
  cancelTicketAPI,
  changPaymentTicketAPI,
  finishTicketAPI,
  inProgressTicketAPI,
} from '../core/_requests'
import { IPayoutTicket } from '../payoutTickets.types'

import '../../../assets/css/pagination.css'
import { getTicket } from '../api/getTicketsList'

const AppSwal = withReactContent(Swal)

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/payout-tickets/active',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Завершённые',
    path: '/payout-tickets/finished',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Отчёт',
    path: '/payout-tickets/report',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
  {
    title: 'Тикет инфо',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
]

const TicketInfo = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const intl = useIntl()
  const { id } = useParams()
  const [ticket, setTicket] = useState<IPayoutTicket | undefined>(undefined)
  const [isFinishedTicket, setIsFinishedTicket] = useState(false)
  const ticketCommissionInput = useRef<HTMLInputElement>(null)

  const getTicketInfo = async () => {
    if (id) {
      const response = await getTicket(id)

      if (!response.data) {
        return false
      }

      if (response.data.success && response.data.ticket) {
        const ticketData = response.data.ticket
        const isFinishedTicket =
          [EPayoutTicketStatus.DECLINED, EPayoutTicketStatus.COMPLETED].indexOf(
            ticketData.status
          ) !== -1 && ticketData.completionDate

        setTicket(ticketData)
        setIsFinishedTicket(!!isFinishedTicket)
      }
    }
  }

  const inProgressTicket = async (token: string) => {
    let response = await inProgressTicketAPI(token)

    handleCancelFinishResponse(response)
  }

  const cancelTicket = async () => {
    if (!ticket) {
      return false
    }

    let response = await cancelTicketAPI(ticket.token)

    handleCancelFinishResponse(response)
  }

  const changePayment = async () => {
    if (!ticket) {
      return false
    }
    const response = await changPaymentTicketAPI(ticket.token)
    if (response?.status === 200) {
      getTicketInfo()
    }
  }

  const finishTicket = async () => {
    if (!ticket) {
      return false
    }

    AppSwal.fire({
      title: '',
      html: (
        <div>
          <label className='fs-6 form-label fw-bolder text-dark d-block'>Укажите комиссию:</label>
          <input
            className='form-control border'
            ref={ticketCommissionInput}
            type='number'
            min={0}
            placeholder='0'
          />
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Завершить тикет',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await finishTicketAPI(ticket.token, ticketCommissionInput.current?.value)

          if (response.status === 400) {
            AppSwal.fire('', 'Невозможно завершить тикет', 'warning')
          }

          if (response.data && response.data.success) {
            navigate('/payout-tickets/active')
          } else {
            let errorFromAPI = ''

            if (Object.entries(response.data.errors).length) {
              Object.entries(response.data.errors).forEach(([key, value]) => {
                errorFromAPI += `<br/ ><span><strong>${key}</strong>: ${value}</span>`
              })
            }
            throw new Error(errorFromAPI)
          }
        } catch (err) {
          AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        AppSwal.fire({
          title: `Успешно!`,
          timer: 1500,
        })
      }
    })
  }

  const handleCancelFinishResponse = (response: AxiosResponse<PayoutTicketActionResponse>) => {
    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.ticket) {
      getTicketInfo()
    }
  }

  const onChangeSelect = async (e: SelectData) => {
    if (!ticket) {
      return false
    }

    const response = await changeCashbox(ticket.token, e.value[0])

    if (!response.data) {
      return false
    }

    setTicket(response?.data?.ticket)

    if (response.data.success) {
      Swal.fire({
        title: 'Касса успешно изменена',
        icon: 'success',
        timer: 1500,
      })
    }
  }

  useEffect(() => {
    getTicketInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !ticket ? (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Тикет инфо</PageTitle>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column text-center'>
            <span className='card-label fw-bold fs-3 mb-1'>Идёт загрузка данных тикета...</span>
          </h3>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Тикет инфо</PageTitle>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Тикет: {id}</span>
          </h3>
          {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) &&
            (ticket?.status === EPayoutTicketStatus.PAYOUT_PENDING ? (
              <div className='col-lg-2'>
                <CashBoxFilter changeSelect={onChangeSelect} value={[ticket?.cashBox?.id]} />
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <p className='fs-5 text-dark'>Касса: {ticket?.cashBox?.title}</p>
              </div>
            ))}
          {isFinishedTicket ? (
            <></>
          ) : (IsGranted(
              [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER],
              currentUser
            ) &&
              ticket.status === EPayoutTicketStatus.PAYOUT_PENDING) ||
            ticket.status === EPayoutTicketStatus.CONFIRMATION_PENDING ? (
            <div>
              <button className='btn btn-success align-self-center me-10' onClick={finishTicket}>
                Завершить
              </button>
              {ticket.status === EPayoutTicketStatus.PAYOUT_PENDING && (
                <button className='btn btn-warning align-self-center me-10' onClick={changePayment}>
                  Отказ
                </button>
              )}
              <button className='btn btn-danger align-self-center' onClick={cancelTicket}>
                Отменить
              </button>
            </div>
          ) : (
            ''
          )}
          {isFinishedTicket ? (
            <></>
          ) : IsGranted(
              [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER],
              currentUser
            ) && ticket.status === EPayoutTicketStatus.ACTION_PENDING ? (
            <div>
              <button
                className='btn btn-success align-self-center me-10'
                onClick={() => inProgressTicket(ticket.token)}
              >
                Взять в работу
              </button>
              <button className='btn btn-danger align-self-center' onClick={cancelTicket}>
                Отменить
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className='d-flex flex-column flex-xl-row'>
        <div className='flex-lg-row-fluid w-100 mb-10'>
          <div className='card mb-5'>
            <div className='card-header card-header-stretch pb-0'>
              <div className='card-title'>
                <h3 className='m-0'>
                  Заявка на вывод средств для <br /> <i>{ticket.externalUser?.externalId}</i>
                </h3>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 py-10 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>
                        {intl.formatMessage({ id: 'ticket.status.' + ticket.status })}
                      </div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Статус</div>
                  </div>
                </div>

                <div className='flex-grow-1'>
                  <div className='row mb-7'>
                    <label className='col-lg-5 fw-bold text-muted'>Клиент</label>
                    <div className='col-lg-7'>
                      <span className='fw-bolder fs-5 text-dark'>
                        {ticket.externalUser?.externalId}
                      </span>
                    </div>
                  </div>

                  {isFinishedTicket ? (
                    <div className='row mb-7'>
                      <label className='col-lg-5 fw-bold text-muted'>Завершил сотрудник</label>
                      <div className='col-lg-7'>
                        <span className='fw-bolder fs-5 text-dark'>
                          {ticket.executor ? ticket.executor.uuid : ''}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className='p-5 rounded bg-light-info text-dark fw-bold w-100 text-start m-auto font-size-15'>
                Фактическая Сумма: {formatSum(ticket.amount)}
                <br />
                Запрошенная Сумма: {formatSum(ticket.requestedAmount)}
                <br />
                Реквизит: {ticket.externalUserRequisite?.identifier}
                <br />
                Тип: {ticket.externalUserRequisite?.type || '-'}
                <br />
                Валюта: {ticket.externalUserRequisite?.currency}
                <br />
                Банк: {ticket?.externalUserRequisite?.acquiringBank || '-'}
                <br />
                Комментарий: {ticket.comment}
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className='flex-column flex-lg-row-auto w-xl-350px ms-lg-15'>
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>Информация</div>
            </div>
            <div className='card-body'>
              <div className='row mb-7'>
                <label className='col-lg-5 fw-bold text-muted'>Создано</label>
                <div className='col-lg-7'>
                  <span className='fw-bolder fs-5 text-dark'>
                    {convertUTCtoMSK(ticket.createdAt)}
                  </span>
                </div>
              </div>

              {isFinishedTicket ? (
                <div className='row mb-7'>
                  <label className='col-lg-5 fw-bold text-muted'>Завершен</label>
                  <div className='col-lg-7'>
                    <span className='fw-bolder fs-5 text-dark'>
                      {ticket.completionDate ? convertUTCtoMSK(ticket.completionDate) : ''}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketInfo
